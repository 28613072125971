const scrollToTop = (elementId) => {
  if (typeof window !== 'undefined') {
    if (elementId) {
      const el = document.getElementById(elementId)
      if (el) {
        window.scrollTo({ left: 0, top: el.offsetTop, behavior: 'smooth' })
        return
      }
    }
    window.scrollTo(0, 0)
  }
}

export default scrollToTop