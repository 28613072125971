import React from 'react'
import styled from 'styled-components'

import { MonoP, InternalLink } from 'src/components/shared/text'

const Pill = styled.div`
  padding: 9px 14px 7px 14px;
  border-radius: 25px;
  height: fit-content;
  width: fit-content;
  display: inline-block;
  margin: 10px 10px 0 -4px;
`

const Text = styled(MonoP)`
  font-size: 12px;
  color: ${(props) => props.theme.colors.navy};
`

const TagPill = styled(Pill)`
  border: 1px solid transparent;
  background-color: ${(props) => props.theme.colors.peach};
  &:hover {
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors.navy};
  }
`

export const BlogTag = ({ copy }) => {
  const slug = copy.toLowerCase().split(' ').join('-')
  return (
    <InternalLink name={copy} to={`/categories/${slug}`}>
      <TagPill>
        <Text>{copy}</Text>
      </TagPill>
    </InternalLink>
  )
}
