import styled from 'styled-components'
import { media } from 'src/utils/style-helper'

const Input = styled.input`
  font-family: ${props  => props.theme.fonts.larsseit.regular};
  text-align: left;
  width: 100%;
  line-height: 1;
  outline: none;
  cursor: pointer;
  font-size: 22px;
  ${media.mobile`
    padding: 11px 24px 14px 24px;
  `}
  ${media.desktop`
    max-width: 300px;
    padding: 14px 24px 16px 24px;
  `}
`

export const TextInput = styled(Input)`
  height: 50px;
  border: none;
  background-color: #f9f9f9;
  border-bottom: 1px solid ${props  => props.theme.colors.slate};
  color: ${props  => props.theme.colors.navy};
  outline: none;
  text-align: center;
  &::placeholder {
    color: ${props  => props.theme.colors.slate};
    font-family: ${props  => props.theme.fonts.larsseit.light};
    text-align: center;
  }
  ${media.mobile`
    padding: 12px 12px 15px 12px;
    margin: 10px 12px 20px 12px;
  `}
  ${media.tablet`
    width: 475px;
    margin: 10px auto 20px auto;
  `}
  ${media.desktop`
    padding: 0 12px 0 12px;
    margin: 0 20px 0 20px;
  `}
`
