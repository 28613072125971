import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultImage: image
      }
    }
  }
`

const SEO = ({ description, keywords, title, image, children }) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const siteData = data.site.siteMetadata
        const metaTitle = title || siteData.defaultTitle
        const metaDescription = description || siteData.defaultDescription
        const metaImage = image?.images?.fallback?.src || siteData.defaultImage

        return (
          <Helmet
            htmlAttributes={{
              lang: 'en',
            }}
            title={metaTitle}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: metaImage,
              },
            ].concat(
              keywords && keywords.length > 0
                ? { name: `keywords`, content: keywords.join(`, `) }
                : []
            )}
          >
            {children}
          </Helmet>
        )
      }}
    />
  )
}

export default SEO
