import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { gsap } from 'gsap'
import qs from 'qs'

import { TextInput } from 'src/components/shared/inputs'
import { PrimaryButton } from 'src/components/shared/buttons'
import { H1, H5, Error } from 'src/components/shared/text'
import { media, getScreenType } from 'src/utils/style-helper'

const Content = styled.section`
  background-color: #f9f9f9;
  border-top: 1px solid ${(props) => props.theme.colors.lightText};
  width: 100%;
  margin: 0 auto;
  ${media.mobile`
    padding: 40px 14px;
  `}
  ${media.tablet`
    padding: 60px 30px;
  `}
  ${media.desktop`
    max-width: 1265px;
    padding: 60px 0 0 0;
  `}
`

const AnimationContainer = styled.div`
  width: 100%;
  position: relative;
  display: grid;
  place-items: center;
  ${media.mobile`
    height: 200px;
  `}
  ${media.tablet`
    height: 170px;
  `}
  ${media.desktop`
    height: 220px;
    margin-bottom: 50px;
  `}
`

const Title = styled(H1)`
  text-align: center;
`

const DoxyIcon = styled.svg`
  position: absolute;
  transform: translate(-100%, 50%);
  bottom: 50%;
  ${media.tablet`
    left: -50%;
  `}
  ${media.desktop`
    left: 10%;
  `}
`

const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  & button {
    margin-left: 20px;
  }
  ${media.mobile`
    flex-direction: column;
  `}
  ${media.tablet`
    flex-direction: row;
  `}
`

const SuccessWrapper = styled.div`
  position: relative;
`

const ThanksWrapper = styled.div`
  transform: translate(-50%, 50%);
  position: absolute;
  left: 50%;
  opacity: 0;
  display: none;
  ${media.mobile`
    width: 100%;
    top: 10%;
  `}
  ${media.tablet`
    width: 700px;
    top: 30%;
  `}
`

const Thanks = styled(H5)`
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.colors.orange};
`

const EmailCapture = () => {
  const { register, handleSubmit } = useForm()
  const [error, setError] = useState(null)

  const screenType = getScreenType()
  const isMobile = screenType === 'mobile' || screenType === 'tablet'

  const onSubmit = async (data) => {
    const klaviyoData = {
      g: 'PPQj8G',
      $fields: '$source',
      ...data,
      $source: 'BlogFooter',
    }
    const stringifiedData = qs.stringify(klaviyoData)
    const response = await axios({
      method: 'post',
      url: '//manage.kmail-lists.com/ajax/subscriptions/subscribe',
      data: stringifiedData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    })

    if (response.data.success) {
      const tl = gsap.timeline()
      tl.to('.EmailCapture_AnimationContainer', {
        x: isMobile ? '300vw' : '150vw',
        duration: 3,
        ease: 'linear',
      })
      tl.to(
        '.EmailCapture_Thanks',
        {
          display: 'block',
          opacity: 1,
          ease: 'linear',
          duration: 0.25,
        },
        '>'
      )
    } else {
      setError('Uh oh. Something went wrong. Please try again!')
    }
  }

  return (
    <Content>
      <Title>A Barking Good Newsletter</Title>
      <SuccessWrapper>
        <AnimationContainer className="EmailCapture_AnimationContainer">
          <DoxyIcon
            width="500"
            height="200"
            viewBox="0 0 900 232"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M233.15 31.5801C229.156 35.6734 224.164 40.3658 219.771 43.96C219.472 44.1597 219.172 44.4592 218.873 44.6589C217.774 45.5574 216.676 46.3561 215.578 47.1548C214.979 47.5542 214.48 48.0534 213.881 48.4527C212.882 49.1516 211.784 49.9503 210.786 50.6492C210.087 51.1484 209.488 51.5477 208.789 52.0469C207.791 52.7458 206.792 53.3448 205.694 54.0437C204.995 54.5429 204.296 54.9422 203.498 55.4414C202.499 56.0405 201.401 56.6395 200.403 57.2385C199.604 57.6379 198.905 58.1371 198.106 58.5364C197.108 59.1354 196.01 59.6346 194.911 60.2337C174.844 70.6168 152.181 76.6071 128.02 76.6071C72.7093 76.6071 24.6871 45.6573 0.126892 0.230835C0.126892 0.330673 0.126892 0.430512 0.126892 0.53035C0.126892 64.1274 51.7433 115.744 115.34 115.744C134.909 115.744 153.379 110.852 169.453 102.266C146.29 142.201 199.304 231.157 199.304 231.157L201.002 122.732C210.985 133.914 225.462 140.903 241.636 140.903C255.813 140.903 268.692 135.512 278.376 126.726C438.816 -4.16205 474.559 193.917 696.599 181.338C687.114 213.186 819.999 231.257 819.999 231.257L743.124 172.851C768.383 162.668 786.753 139.006 789.349 110.752C831.181 163.466 899.171 154.781 899.171 154.781L854.443 76.9067C838.27 48.8521 812.511 31.5801 791.346 31.5801V84.6941C791.346 99.4701 779.365 111.351 764.689 111.351C749.913 111.351 738.032 99.3703 738.032 84.6941C738.032 56.939 759.297 34.076 786.453 31.5801H233.15ZM820.199 82.5974C815.806 82.5974 812.312 79.1031 812.312 74.7102C812.312 70.3173 815.806 66.823 820.199 66.823C824.592 66.823 828.086 70.3173 828.086 74.7102C828.086 79.1031 824.492 82.5974 820.199 82.5974Z"
              fill="#161345"
            />
          </DoxyIcon>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="g" value="PPQj8G" />
            <TextInput
              name="email"
              type="email"
              placeholder="Enter Your Email"
              ref={register}
              required
            />
            <PrimaryButton
              arrow
              copy="Sign Up"
              bg={(props) => props.theme.colors.orange}
              textColor={(props) => props.theme.colors.navy}
            />
          </Form>
          {error && <Error>{error}</Error>}
        </AnimationContainer>
        <ThanksWrapper className="EmailCapture_Thanks">
          <Thanks>Thanks, welcome to the pack!</Thanks>
        </ThanksWrapper>
      </SuccessWrapper>
    </Content>
  )
}

export default EmailCapture
