import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { BlogTag } from 'src/components/shared/pills'
import { LargeP, InternalLink } from 'src/components/shared/text'
import scrollToTop from 'src/utils/scrollToTop'
import { media } from 'src/utils/style-helper'

const Container = styled.div`
  ${media.mobile`
    width: 93vw;
  `}
  ${media.tablet`
    width: ${(props) => props.width};
  `}
`

const ImageWrapper = styled.div`
  width: 100%;
  margin: 0 auto 16px auto;
  transition: 150ms all ease-in-out;
  margin-bottom: 20px;
`

const Link = styled(InternalLink)`
  &:hover {
    ${ImageWrapper} {
      transform: scale(1.01);
    }
  }
`

const Title = styled(LargeP)`
  padding: 0 6px;
  ${media.mobile`
    font-size: 28px;
    line-height: 36px;
  `}
  ${media.tablet`
    font-size: 30px;
    line-height: 36px;
  `}
  ${media.desktop`
    font-size: ${(props) => (props.smallText ? 18 : 24)}px;
    line-height: ${(props) => (props.smallText ? 22 : 30)}px;
  `}
`

const PaddingWrapper = styled.div`
  padding: 0 6px;
`

const SmallBlogCard = ({ post, width = '100%', smallText = false }) => {
  const featuredImage = getImage(post.featuredimage)

  return (
    <Container width={width}>
      <Link to={`/articles/${post.slug}`} onClick={scrollToTop}>
        {featuredImage && (
          <ImageWrapper>
            <GatsbyImage
              image={featuredImage}
              alt={post.heroalt || post.title}
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'cover' }}
            />
          </ImageWrapper>
        )}
        <Title smallText={smallText}>{post.title}</Title>
      </Link>
      <PaddingWrapper>
        {post.tags.map((tag) => (
          <BlogTag copy={tag} key={tag} />
        ))}
      </PaddingWrapper>
    </Container>
  )
}

export default SmallBlogCard
